import { useState, useRef, useEffect } from "react";
import Popup from "./Popup";
import ClearIcon from "./ClearIcon";
import CopyIcon from "./CopyIcon";
import DownloadIcon from "./DownloadIcon";
import CheckIcon from "./CheckIcon";
import InfoIcon from "./InfoIcon";
import "./App.scss";

function App() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState(null);
  const [copied, setCopied] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [definition, setDefinition] = useState("");
  const [isShowDefinition, setIsShowDefinition] = useState(false);
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);
  const textareaEl = useRef(null);
  const outputEl = useRef(null);

  const toggleDefinition = (word) => {
    const newValue = !isShowDefinition;
    setIsShowDefinition(newValue);

    if (newValue) {
      fetchDefinition(word);
    } else {
      setDefinition("");
    }
  };

  const run = async () => {
    if (input) {
      setOutput([]);
      const response = await fetch(`https://api.clcode.net/pinyin/transform`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: input }),
      });
      const result = await response.json();
      setOutput(result);
    }
  };

  const clear = () => {
    setInput("");
    textareaEl.current.focus();
  };

  const fetchDefinition = async (word) => {
    const response = await fetch(
      `https://api.clcode.net/hanzi/search?q=${word}`
    );
    const result = await response.json();
    setDefinition(result);
  };

  const addScript = (url, onload) => {
    var script = document.createElement("script");
    script.type = "application/javascript";
    script.src = url;
    script.onload = onload;
    document.head.appendChild(script);
  };

  const downloadByAnchor = (data) => {
    var fileURL = URL.createObjectURL(data);
    var a = document.createElement("a");
    a.href = fileURL;
    a.target = "_blank";
    a.download = "pinyin.pdf";
    document.body.appendChild(a);
    a.click();
  };

  const downloadByDataURL = (data) => {
    var reader = new FileReader();
    var out = new Blob([data], { type: "application/pdf" });
    reader.onload = function (e) {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(out);
  };

  const download = () => {
    setDownloaded(true);
    setTimeout(() => {
      setDownloaded(false);
    }, 3000);

    setTimeout(() => {
      if (!window.html2pdf) {
        addScript("./html2pdf.bundle.min.js", () => {
          downloadFile();
        });
      } else {
        downloadFile();
      }
    });
  };

  const isIOSChrome = () => {
    return navigator.userAgent.match("CriOS");
  };

  const saveAsPdf = () => {
    if (isIOSChrome()) {
      saveAsPdfForIOSChrome();
    } else {
      saveAsPdfCommon();
    }
  };

  const saveAsPdfCommon = () => {
    const worker = window.html2pdf();
    const options = {
      margin: 10,
      filename: "pinyin.pdf",
    };
    worker.set(options).from(outputEl.current).save();
  };

  const saveAsPdfForIOSChrome = () => {
    // Maybe For iOS Chrome
    const worker = window.html2pdf();
    const options = {
      filename: "pinyin.pdf",
    };
    worker
      .set(options)
      .from(outputEl.current)
      .outputPdf("blob")
      .then((result) => {
        downloadByDataURL(result);
        // downloadByAnchor(result);
      });
  };

  const downloadFile = () => {
    setSelectStyleForPrint();

    saveAsPdf();

    resetSelectStyleForPrint();
  };

  const setSelectStyleForPrint = () => {
    document.querySelectorAll("select").forEach((node) => {
      if (window.innerWidth < 500) {
        node.style.background = "transparent";
        node.__saved_padding = node.style.padding;
        node.style.padding = 0;
      } else {
        node.style.appearance = "none";
      }
    });
  };

  const resetSelectStyleForPrint = () => {
    setTimeout(() => {
      document.querySelectorAll("select").forEach((node) => {
        if (window.innerWidth < 500) {
          node.style.background = "#ddd";
          node.style.padding = node.__saved_padding;
          delete node.__saved_padding;
        } else {
          node.style.appearance = "auto";
        }
      });
    });
  };

  const addSpaceBetweenWord = (input) => {
    input = input.replace(/ /g, "");
    return input.replace(/([\u4e00-\u9fff])([^\u4e00-\u9fff])/g, function (
      match,
      p1,
      p2
    ) {
      return `${p1} ${p2}`;
    });
  };

  const copy = () => {
    const textToCopy = addSpaceBetweenWord(outputEl.current.textContent);
    navigator.clipboard.writeText(textToCopy);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const inputClass = output ? "hide" : "show";

  const onClosePopup = () => {
    setOutput(null);
  };

  const onChange = (e) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      textareaEl.current.focus();
    }, 300);
  }, []);

  const renderOutput = () => {
    if (output && output.length) {
      return output.map(render);
    } else {
      return <div className="loading">⏳</div>;
    }
  };

  const render = ({ word, pinyin }, index) => {
    return (
      <span key={index} className="word">
        {renderPinyin(pinyin)}
        <span className="text" onClick={() => toggleDefinition(word)}>
          {word}
        </span>
      </span>
    );
  };

  const renderPinyin = (pinyin) => {
    const pinyins = pinyin?.split(",") || [];
    let content;
    if (pinyins.length > 1) {
      content = (
        <select>
          {pinyins.map((p) => (
            <option key={p}>{p}</option>
          ))}
        </select>
      );
    } else {
      content = pinyins[0];
    }
    return <span className="pinyin">{content}</span>;
  };

  const toggleDisclaimer = () => {
    setIsShowDisclaimer(!isShowDisclaimer);
  }

  const renderTools = () => {
    if (!output || !output.length) {
      return null;
    }
    return (
      <div className="tools">
        <div className="tip" onClick={toggleDisclaimer}>
          <div className="tip-text">点击汉字查看更多</div>
          <InfoIcon />
        </div>
        <div className="icons">
          {!downloaded ? (
            <span>
              <DownloadIcon onClick={download} />
            </span>
          ) : (
            <span>
              <CheckIcon />
            </span>
          )}
          {!copied ? (
            <span>
              <CopyIcon onClick={copy} />
            </span>
          ) : (
            <span>
              <CheckIcon />
            </span>
          )}

        </div>
      </div>
    );
  };

  const playAudio = (item) => {
    const audio = document.createElement('audio');
    audio.src = `https://pinyin.clcode.net/hanzi-audios/${item}.mp3`;
    document.body.appendChild(audio);
    audio.play();
    setTimeout(() => {
      document.body.removeChild(audio);
    }, 2000);
  };

  const renderPronounciation = (item) => {
    return (
      <button
        className="pronounciation"
        key={item}
        onClick={() => playAudio(item)}
        tabIndex={1}
      >
        <span>{item}</span>
        <img alt="audio" src="/audio.svg" />
      </button>
    );
  };

  const renderSense = (sense, index, senses) => {
    const { pronounciation, partOfSpeech, defs } = sense;
    const key = pronounciation + partOfSpeech + defs[0] + index;
    const isSameToPrevious =
      index > 0 && senses[index - 1].pronounciation === pronounciation;
    return (
      <div className="sense" key={key}>
        {!isSameToPrevious && renderPronounciation(pronounciation)}
        {partOfSpeech && <div className="part-of-speech">{partOfSpeech}</div>}
        <ol className="defs">{defs.map(renderDef)}</ol>
      </div>
    );
  };

  const renderDef = (def, index) => {
    const { value, examples, classicalExamples } = def;
    return (
      <li className="def" key={value}>
        <div className="value">{value}</div>
        <div className="examples">{examples.join(" · ")}</div>
        {classicalExamples.length > 0 && (
          <div className="classical-examples">
            {classicalExamples.map(renderClassicExample)}
          </div>
        )}
      </li>
    );
  };

  const renderClassicExample = (example) => {
    return <div key={example}>{example}</div>;
  };

  const renderDefinition = () => {
    if (!definition) {
      return <div className="loading">⏳</div>;
    }

    const {
      text,
      traditionalText,
      allPronounciations,
      senses,
      languages,
      origin,
    } = definition;
    return (
      <div className="definition">
        <div className="head">
          <span className="text">
            <span>{text}</span>
            {traditionalText !== text && <span>（{traditionalText}）</span>}
          </span>
          <span className="pronounciations">
            {allPronounciations.map(renderPronounciation)}
          </span>
        </div>

        <div className="body">
          <div className="senses">{senses.map(renderSense)}</div>
          <div className="languages">
            <div className="language">
              <span className="type">英语</span>
              <span className="value">{languages.English}</span>
            </div>
            <div className="language">
              <span className="type">日语</span>
              <span className="value">{languages.Japanese?.replaceAll(' ', ', ').replaceAll('/', ' · ')}</span>
            </div>
          </div>
          {origin?.category && (
            <div className="origin">
              <span className="label">说文解字 {origin.category} </span>
              <span className="value">{origin.description}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      <div className="title">
        <img className="logo" alt="logo" src="/pinyin.svg" />
        汉字转拼音
      </div>

      <div className="buttons">
        <button className="run" onFocus={run} onClick={run}>
          转换
        </button>
        {Boolean(input) && (
          <div className="clear" onClick={clear}>
            <ClearIcon />
          </div>
        )}
      </div>

      <div className="body">
        <div className={`input ${inputClass}`}>
          <textarea
            placeholder="请输入要转换的汉字或文本"
            ref={textareaEl}
            cols="90"
            rows="10"
            value={input}
            onChange={onChange}
            autoFocus
          ></textarea>
        </div>

        <Popup show={Boolean(output)} onClose={onClosePopup}>
          <div className="toolbar">{renderTools()}</div>
          <div className="output" ref={outputEl}>
            {renderOutput()}
          </div>
        </Popup>

        <Popup show={isShowDefinition} onClose={toggleDefinition}>
          <div>{renderDefinition()}</div>
        </Popup>

        <Popup show={isShowDisclaimer} onClose={toggleDisclaimer}>
        <div className="disclaimer">数据来源于网络、"pinyin-data" 项目 、汉典、國語辭典等，仅作为参考</div>
        </Popup>
      </div>
    </div>
  );
}

export default App;
