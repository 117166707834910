import { useRef, useEffect } from "react";
import "./Popup.scss";

export default function Popup(props) {
  const { show, onClose } = props;
  const containerEl = useRef(null);

  useEffect(() => {
    if (show) {
      containerEl.current.style.transform = "scale(1)";
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const handleClose = () => {
    onClose?.();
  };

  const preventClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="popup-container" onClick={handleClose}>
      <div className="popup-content" onClick={preventClick} ref={containerEl}>
        {props.children}
      </div>
    </div>
  );
}
